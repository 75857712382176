














































































import { Component, Vue, Prop } from "vue-property-decorator";
import MainHeader from "@/components/main-header/main-header.vue";
import { GetBookMulu } from "@/request/storehouse";
import { BookMuluText } from "@/utils/base";
@Component({
  components: {
    MainHeader,
  },
})
export default class Name extends Vue {
  private source: any = "";
  private data: any = {};
  private isOpenJianjie: any = false;
  private get currentKu() {
    return this.$store.state.currentKu;
  }
  private getBookMuluText(item: any) {
    return BookMuluText(item);
  }
  private goRead(item: any) {
    this.$router.push({
      path: "/main/storehouse/guji-read",
      query: {
        source: this.source,
        id: item._id,
        title: this.data["书名"].split("《")[1].split("》")[0],
      },
    });
  }
  private getMulu() {
    const params: any = {
      params: {
        book_id: this.data.book_id,
      },
    };
    GetBookMulu(this, params).then((res: any) => {
      this.data = res;
    });
  }
  private mounted() {
    this.data.book_id = this.$route.query.id;
    this.source = this.$route.query.source;
    this.getMulu();
  }
}
